window.mode_liff = 0;

if (liff.isInClient()) {
    // LIFFの初期化を行う
    liff.init({
        // 自分のLIFF ID（URLから『https://liff.line.me/』を除いた文字列）を入力する
        liffId: liffId,
    }).then(() => {
        // 初期化完了
        mode_liff = 1;
    });
} else {
    // 通常起動
}
